import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import UserInfo from "../components/UserInfo/UserInfo";
import PostTags from "../components/PostTags/PostTags";
import { MDXRenderer } from "gatsby-plugin-mdx";
import SEO from "../components/SEO/SEO";
import Cover from "../components/PostListing/Cover";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.scss";

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.mdx;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    // const renderAst = new rehypeReact({
    //   createElement: React.createElement,
    //   components: { "photo": Photo }
    // }).Compiler;

    return (
      <Layout>
        <div className="single-post">
          <Cover src={post.cover} title={post.title} />
          <Helmet>
            <title>{`${post.title} ♡ ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <div>
            <div className="post-date">{post.date}</div>
            <div className="post-title">
              <h2>{post.title}</h2>
            </div>
            <PostTags tags={post.tags} />
            <MDXRenderer>{postNode.body}</MDXRenderer>
            <UserInfo config={config} />
          </div>
        </div>
      </Layout>
    );
  }
}

// <div>{renderAst(post.htmlAst)}</div>

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;